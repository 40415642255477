import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

const ThankYouPage = props => {
  const post = props.data.allUniquePagesJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={props.location.pathname}
        />
        <div className="joshua-tree-content">
          <div className="columns">
            <div className="column">
              <div className="columns top-section text-section is-mobile color-back">
                <div className="column is-4" />
                <div className="column is-16">
                  <div
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.body)
                    )}
                  />
                </div>
                <div className="column is-4" />
              </div>
              <div className="column is-4" />
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query thankYouPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        body
        metaTitle
        metaDescription
      }
    }
  }
`

export default ThankYouPage
